// Cloudinary
export const REACT_APP_CLOUDINARY_API_KAY: string = process.env.REACT_APP_CLOUDINARY_API_KAY || '768673679571263'
// export const REACT_APP_CLOUDINARY_API_KAY: string = process.env.REACT_APP_CLOUDINARY_API_KAY || '821279233882751'
export const REACT_APP_CLOUDINARY_API_SECRET: string = process.env.REACT_APP_CLOUDINARY_API_SECRET || 'qysybldlJWs_RHWH3CUM8NoStSA'
// export const REACT_APP_CLOUDINARY_API_SECRET: string = process.env.REACT_APP_CLOUDINARY_API_SECRET || 'RXcPZVDEzyS9ynNC-VAmlDK78IQ'
export const REACT_APP_CLOUDINARY_UPLOAD_PRESET: string = process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET || 'pxrbylo3_ttarasenkoart.com'
export const REACT_APP_CLOUDINARY_API_URL: string = process.env.REACT_APP_CLOUDINARY_API_URL || 'https://api.cloudinary.com/v1_1/dy4mln81z/image/upload'

// export const SERVER_API: string = 'https://server-ttarasenkoart.herokuapp.com/'
// export const SERVER_API: string = 'http://localhost:8000/'
export const SERVER_API: string = 'https://api.ttarasenkoart.com/'
// export const SERVER_API: string = 'https://ttarasenkoart-server.onrender.com/'
