// CATEGORIES
export const GET_CATEGORIES = 'GET_CATEGORIES'

// PICTURES
export const GET_PICTURES = 'GET_PICTURES'
export const GET_PICTURE = 'GET_PICTURE'
export const ADD_PICTURE = 'ADD_PICTURE'
export const EDIT_PICTURE = 'EDIT_PICTURE'
export const REMOVE_PICTURE = 'REMOVE_PICTURE'

// CV
export const GET_ALL_CV = 'GET_ALL_CV'
export const GET_CV = 'GET_CV'
export const ADD_CV = 'ADD_CV'
export const EDIT_CV = 'EDIT_CV'
export const REMOVE_CV = 'REMOVE_CV'